import React from "react";
import Image, { StaticImageData } from "next/image";

interface StoreSectionProps {
  banner: StaticImageData;
  banner_alt: string;
  heading: string;
  link: string;
  captionText?: string;
}

const StoreSection: React.FC<StoreSectionProps> = ({
  banner,
  banner_alt,
  heading,
  link,
  captionText,
}) => {
  return (
    <div className="group h-full w-full text-primary-light antialiased">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="relative inset-0 z-0 min-h-[200px] md:min-h-[250px]">
          <Image
            className=" transition duration-300 ease-in-out group-hover:opacity-70"
            src={banner}
            alt={banner_alt}
            fill
            placeholder="blur"
            style={{
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
          <div className="absolute inset-0 h-full w-full bg-black/70">
            <div className="mx-auto flex h-full w-full max-w-[1240px] flex-col items-center justify-center p-6 font-libre-baskerville">
              <h1 className="text-center text-xl tracking-wide hover:underline sm:text-3xl md:text-4xl">
                {heading}
              </h1>
              {captionText && (
                <p className="mt-2 text-sm sm:text-base md:text-sm">
                  {captionText}
                </p>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default StoreSection;
