import Navbar from "../components/Navbar";
import LandingPage from "../components/LandingPage";
import AboutSection from "../components/AboutSection";
import VideosSection from "../components/VideosSection";
import StoreSection from "../components/StoreSection";

import shop_banner_one from "../public/shop_banner_one.png";
import shop_banner_two from "../public/shop_banner_two.png";
import LatestUpdatesSection from "../components/LatestUpdatesSection";
import { GetStaticProps } from "next";
import {
  convertLocale,
  getNewsArticleList,
  getAboutSection,
  getLandingImages,
  getSEOImage,
  getVideoCarousel,
  shouldDisplayChineseLocale,
} from "../util/service";
import { NewsArticleData, optimizedImageProps } from "../types";

import { useTranslations } from "next-intl";
import { NextSeo } from "next-seo";
import { getPlaiceholder } from "plaiceholder";
import Head from "next/head";

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const _locale = shouldDisplayChineseLocale(locale) ? "zh" : locale;
  const loc = convertLocale(locale);
  const [seoImage, landingImages, news, about, videoCarousel] =
    await Promise.all([
      getSEOImage(),
      getLandingImages(),
      getNewsArticleList(loc, 3),
      getAboutSection(loc),
      getVideoCarousel(loc),
    ]);

  /* base64 image placeholder */

  // video carousel
  const videoCarousel_optimized = await Promise.all(
    videoCarousel.map(async (video) => {
      const { base64, img } = await getPlaiceholder(video.image);
      return {
        src: video.src,
        imageProps: {
          imageURL: img.src,
          blurDataURL: base64,
        },
      };
    })
  );

  const landing_images_optimized = await Promise.all(
    landingImages.map(async (image) => {
      const { base64, img } = await getPlaiceholder(image, { size: 64 });
      return {
        imageURL: img.src,
        blurDataURL: base64,
      };
    })
  );

  // news
  const news_data = news.data ?? [];

  for (let i = 0; i < news_data.length; i++) {
    const { base64, img } = await getPlaiceholder(
      news_data[i].attributes.cover?.data.attributes.url ?? ""
    );
    news_data[i].attributes.blurDataURL = base64;
  }

  return {
    props: {
      seoImage,
      landing_images_optimized,
      videoCarousel_optimized,
      news: news.data ?? [],
      about: about,
      messages: (await import(`../locales/${_locale}/common.json`)).default,
    },
    revalidate: 60,
  };
};

export default function Home({
  news,
  about,
  landing_images_optimized,
  seoImage,
  videoCarousel_optimized,
}: {
  news: NewsArticleData[];
  about: string;
  landing_images_optimized: optimizedImageProps[];
  seoImage: string;
  videoCarousel_optimized: {
    src: string;
    imageProps: optimizedImageProps;
  }[];
}) {
  const t = useTranslations("Landing.storeSection");
  return (
    <>
      <Head>
        <meta property="twitter:image" content={seoImage} />
        <meta property="twitter:title" content="Animenz Piano Sheets" />
        <meta
          property="twitter:description"
          content="Anime Piano Arranger / Steinway Artist. I am a classically trained pianist making advanced piano arrangements of Anime Openings, Endings and Soundtracks since 2010! My Goal is to make Anime music into 'Classic music' which will be appreciated in 200 years and beyond!"
        />
      </Head>
      <NextSeo
        title="Animenz Piano Sheets"
        description='Anime Piano Arranger / Steinway Artist. I am a classically trained pianist making advanced piano arrangements of Anime Openings, Endings and Soundtracks since 2010! My Goal is to make Anime music into "Classic music" which will be appreciated in 200 years and beyond!'
        openGraph={{
          images: [
            {
              url: seoImage,
              width: 1200,
              height: 630,
              alt: "Animenz Piano Sheet",
            },
          ],
        }}
      />
      <Navbar />
      <LandingPage landing_images={landing_images_optimized} />
      <AboutSection about={about} />
      <VideosSection video_list={videoCarousel_optimized} />
      <LatestUpdatesSection news={news} />
      <StoreSection
        heading={t("storeOne.title")}
        banner={shop_banner_one}
        banner_alt={t("storeOne.altbanner")}
        captionText={t("storeOne.caption")}
        link={t("storeOne.link")}
      />
      <StoreSection
        heading={t("storeTwo.title")}
        banner={shop_banner_two}
        banner_alt={t("storeTwo.altbanner")}
        captionText={t("storeTwo.caption")}
        link={t("storeTwo.link")}
      />
    </>
  );
}
