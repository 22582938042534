import React from "react";
import Image from "next/image";

interface ImageProps {
  blurDataURL?: string;
  src: string;
  alt: string;
}

export const SquareImage: React.FC<ImageProps> = ({
  src,
  alt,
  blurDataURL,
}) => {
  return (
    <div className="relative aspect-square">
      <Image
        className="object-cover"
        src={src}
        alt={alt}
        fill
        sizes="350px"
        blurDataURL={blurDataURL}
        placeholder={blurDataURL ? "blur" : "empty"}
      />
    </div>
  );
};

export const WideImage: React.FC<ImageProps> = ({ src, alt, blurDataURL }) => {
  return (
    <div className="relative aspect-video">
      <Image
        className="object-cover"
        src={src}
        alt={alt}
        fill
        sizes="350px"
        blurDataURL={blurDataURL}
        placeholder={blurDataURL ? "blur" : "empty"}
      />
    </div>
  );
};
