import React, { useEffect, useState } from "react";
import { socials_general, socials_zh } from "../public/data";

import Image from "next/image";
import { useRouter } from "next/router";

import { useTranslations } from "next-intl";

import { motion, AnimatePresence } from "framer-motion";
import { optimizedImageProps } from "../types";
import { shouldDisplayChineseLocale } from "../util/service";

interface LandingPageProps {
  landing_images: optimizedImageProps[];
}

const LandingPage: React.FC<LandingPageProps> = ({ landing_images }) => {
  const { locale } = useRouter();

  const socials = shouldDisplayChineseLocale(locale)
    ? socials_zh
    : socials_general;

  const t = useTranslations("Landing");

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % landing_images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [landing_images]);

  return (
    <div className="h-screen w-full">
      <div className="relative inset-0 z-0 h-screen w-full transition-transform duration-1000 ease-in-out">
        <AnimatePresence>
          <motion.div
            key={currentImage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="absolute inset-0 z-0 h-full w-full"
          >
            <ImageCaourselWithPreload
              currentImage={currentImage}
              landing_images={landing_images}
            />
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="absolute inset-0 h-screen w-full bg-zinc-800/40">
        <div className="mx-auto flex h-full w-full max-w-[1240px] items-center justify-end p-6 text-slate-200">
          <div className="flex flex-col items-center justify-between text-right font-libre-baskerville max-sm:h-full max-sm:w-full md:-translate-y-1/4">
            <div className="w-full max-sm:translate-y-[25%] max-sm:p-4">
              <div className="ml-auto max-w-[520px] break-words py-5 text-2xl font-bold leading-snug max-[285px]:text-xl sm:text-3xl md:text-4xl">
                {shouldDisplayChineseLocale(locale) ? (
                  <div>
                    <p>{t("landingQuoteOne")}</p>
                    <p>{t("landingQuoteTwo")}</p>
                  </div>
                ) : (
                  <div>{t("landingQuote")}</div>
                )}
              </div>
              <p>{t("landingQuoteAuthor")}</p>
            </div>
            <div className="flex max-w-[200px] items-center justify-center gap-10 object-bottom py-4 text-3xl max-sm:mx-auto max-[285px]:text-xl sm:ml-auto md:max-w-[330px]">
              {socials.map((social, index) => {
                const { icon, href } = social;
                return (
                  <a
                    href={href}
                    key={index}
                    target="_blank"
                    className="cursor-pointer "
                    rel="noreferrer noopener"
                  >
                    {icon}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ImageCaourselWithPreload: React.FC<{
  currentImage: number;
  landing_images: optimizedImageProps[];
}> = ({ currentImage, landing_images }) => {
  return (
    <>
      <Image
        src={landing_images[currentImage].imageURL}
        blurDataURL={landing_images[currentImage].blurDataURL}
        placeholder="blur"
        alt="landing_image"
        fill
        quality={100}
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        priority={true}
      />
      <Image
        src={
          landing_images[
            currentImage + 1 < landing_images.length ? currentImage + 1 : 0
          ].imageURL
        }
        blurDataURL={
          landing_images[
            currentImage + 1 < landing_images.length ? currentImage + 1 : 0
          ].blurDataURL
        }
        placeholder="blur"
        alt="landing_image"
        fill
        className="invisible"
        quality={100}
        style={{
          objectFit: "cover",
          objectPosition: "center",
        }}
        priority={true}
      />
    </>
  );
};

export default LandingPage;
