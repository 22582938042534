import React, { useEffect } from "react";
import Image from "next/image";
import { useRef, useState } from "react";
import { useTranslations } from "next-intl";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { useRouter } from "next/router";
import { optimizedImageProps } from "../types";
import { shouldDisplayChineseLocale } from "../util/service";

interface VideoSectionProps {
  video_list: {
    src: string;
    imageProps: optimizedImageProps;
  }[];
}

const VideosSection: React.FC<VideoSectionProps> = ({ video_list }) => {
  const { locale } = useRouter();

  const slider = useRef() as React.MutableRefObject<HTMLDivElement>;

  const slideLeft = () => {
    slider.current.scrollLeft = slider.current.scrollLeft - 1323;
  };

  const slideRight = () => {
    slider.current.scrollLeft = slider.current.scrollLeft + 1323;
  };
  const [src, setSrc] = useState(video_list[0].src);
  useEffect(() => {
    setSrc(video_list[0].src);
  }, [video_list]);

  const t = useTranslations("Landing.videoSection");
  return (
    <>
      <div
        className="h-full w-full bg-black-navy text-primary-light antialiased"
        id="videos"
      >
        <div className="mx-auto max-w-[1240px]">
          <div className="w-full text-center">
            <h1 className="p-7 pt-10 text-center font-libre-baskerville text-2xl uppercase md:p-16 md:text-3xl">
              {t("title")}
            </h1>
            <div className="relative mx-auto aspect-video max-h-[485px] max-w-[863px] overflow-hidden">
              {!shouldDisplayChineseLocale(locale) ? (
                <iframe
                  className="abosolute top-0 left-0"
                  width="100%"
                  height="100%"
                  src={src}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  src={src}
                  title="Bilibili video player"
                ></iframe>
              )}
            </div>
          </div>
          <div className="h-full w-full p-4">
            <div className="flex h-full w-full items-center justify-center">
              <MdChevronLeft
                size={100}
                className="m-3 hidden cursor-pointer text-slate-400 hover:text-white md:block"
                onClick={slideLeft}
              />
              <div
                ref={slider}
                className="scrollbar-hide flex gap-4 overflow-x-scroll scroll-smooth py-5"
              >
                {video_list.map((item, index) => {
                  return (
                    <Image
                      src={item.imageProps.imageURL}
                      blurDataURL={item.imageProps.blurDataURL}
                      placeholder="blur"
                      width={300}
                      height={169}
                      alt="thumbnail image"
                      style={{
                        width: "auto" || "300px",
                        height: "auto" || "169px",
                      }}
                      key={index}
                      onClick={() => {
                        setSrc(item.src);
                      }}
                      className={
                        src == item.src
                          ? "mx-2 cursor-pointer border transition duration-300 hover:scale-105"
                          : "mx-2 cursor-pointer transition duration-300 hover:scale-105"
                      }
                    />
                  );
                })}
              </div>
              <MdChevronRight
                size={100}
                className="m-3 hidden cursor-pointer text-slate-400 hover:text-white md:block"
                onClick={slideRight}
              />
            </div>
            <div className="block text-center text-sm text-slate-300 md:hidden">
              {t("scrollText")} &#8594;
            </div>
          </div>
          <div className="pb-10">
            <div className="flex justify-center">
              <a
                href={
                  locale?.includes("zh")
                    ? "https://space.bilibili.com/6075139"
                    : "https://www.youtube.com/@Animenzzz"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="bg-clear text-md mx-auto block border border-primary-light py-2 px-6 uppercase text-primary-light">
                  {t("buttonText")}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideosSection;
