import React from "react";
import Link from "next/link";
import { useTranslations } from "next-intl";

const AboutSection: React.FC<{ about: string }> = ({ about }) => {
  const t = useTranslations("Landing.aboutSection");
  return (
    <div className="h-full w-full bg-timberwolf antialiased">
      <div className="mx-auto h-full max-w-[1240px] text-black">
        <div className="h-full w-full text-center">
          <h1 className="pt-10 text-center font-libre-baskerville text-2xl uppercase md:pt-16 md:text-3xl ">
            {t("title")}
          </h1>
          <p className="mx-auto max-w-[1000px] p-5 md:p-10">{about}</p>
          <div className="pb-10">
            <div className="flex justify-center">
              <Link href="/about">
                <button className="bg-clear text-md mx-auto block border border-black py-2 px-6 uppercase text-black">
                  {t("buttonText")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
