import { NewsArticleData } from "../types";
import React from "react";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { VscPinned } from "react-icons/vsc";
import { useLocalDateString } from "../util/hooks";
import { SquareImage, WideImage } from "./Images";

interface NewsCardProps {
  news: NewsArticleData;
  pinned?: boolean;
}

export const NewsCard: React.FC<NewsCardProps> = ({
  news: {
    attributes: { slug, cover, date, title, overview, blurDataURL },
  },
  pinned,
}) => {
  const t = useTranslations("News");
  const formattedDate = useLocalDateString(date);
  return (
    <div className="flex aspect-square flex-col overflow-hidden rounded-[5px] bg-cultured transition hover:shadow-xl">
      <WideImage
        src={cover?.data.attributes.url ?? ""}
        alt=""
        blurDataURL={blurDataURL}
      />
      <div className="flex grow flex-col p-4">
        <div className="flex items-center pb-1 text-dim-gray">
          {pinned && <VscPinned className="pr-[2px] text-[18px]" />}
          <div className="text-xs">{formattedDate}</div>
        </div>
        <Link href={`/news/${slug}`}>
          <p className="text-md truncate pb-1.5 font-libre-baskerville font-bold text-black hover:underline">
            {title}
          </p>
        </Link>
        <div className="grow">
          <div className="overflow-hidden text-xs text-dim-gray line-clamp-3">
            {overview}
          </div>
        </div>
        <Link href={`/news/${slug}`}>
          <p className="pt-[6px] text-xs font-bold uppercase text-dim-gray hover:underline">
            {t("readMoreText")}
          </p>
        </Link>
      </div>
    </div>
  );
};

interface NewsCardLongProps {
  news: NewsArticleData;
  direction: "vertical" | "image-left" | "image-right";
  wideImage?: boolean;
}

export const NewsCardLong: React.FC<NewsCardLongProps> = ({
  news: {
    attributes: { date, title, slug, overview, cover, blurDataURL },
  },
  direction,
  wideImage = false,
}) => {
  let layoutClassName = "";
  switch (direction) {
    case "vertical":
      layoutClassName =
        "w-full h-full bg-cultured rounded-[5px] hover:shadow-xl transition overflow-hidden flex flex-col";
      break;
    case "image-left":
      layoutClassName =
        "w-full h-full bg-cultured rounded-[5px] hover:shadow-xl transition overflow-hidden flex flex-row";
      break;
    case "image-right":
      layoutClassName =
        "w-full h-full bg-cultured rounded-[5px] hover:shadow-xl transition overflow-hidden flex flex-row-reverse";
      break;
  }
  const t = useTranslations("Landing.latestUpdatesSection.card");
  const formattedDate = useLocalDateString(date);
  return (
    <div className={layoutClassName}>
      {wideImage ? (
        <WideImage
          src={cover?.data.attributes.url ?? ""}
          alt=""
          blurDataURL={blurDataURL}
        />
      ) : (
        <SquareImage
          src={cover?.data.attributes.url ?? ""}
          alt=""
          blurDataURL={blurDataURL}
        />
      )}
      <div className="flex max-w-[420px] grow flex-col p-4">
        <p className="pb-1 text-xs text-dim-gray">{formattedDate}</p>
        <Link href={`/news/${slug}`}>
          <p className="text-md truncate pb-1.5 font-libre-baskerville font-bold text-black hover:underline">
            {title}
          </p>
        </Link>
        <div className="grow">
          <div className="overflow-hidden text-xs text-dim-gray line-clamp-4">
            {overview}
          </div>
        </div>
        <Link href={`/news/${slug}`}>
          <p className="pt-[6px] text-xs font-bold uppercase text-dim-gray hover:underline">
            {t("readMore")}
          </p>
        </Link>
      </div>
    </div>
  );
};
