import React from "react";
import Link from "next/link";
import { NewsArticleData } from "../types";
import { useTranslations } from "next-intl";
import { NewsCardLong } from "./NewsCard";

interface LatestUpdatesSectionProps {
  news: NewsArticleData[];
}

const LatestUpdatesSection: React.FC<LatestUpdatesSectionProps> = ({
  news,
}) => {
  const [first, second, third] = news;
  const t = useTranslations("Landing.latestUpdatesSection");
  return (
    <div
      className="
        flex
        h-[1430px] w-full flex-col
        place-items-center bg-timberwolf antialiased
        md:h-[1060px] min-[1131px]:h-[820px]
      "
    >
      {/* <div className="overflow-hidden h-full w-[100%]">
        <div className="relative w-[3000px] aspect-[2/1] translate-x-[calc(100vw-2500px)]">
          <Image src="/streamlines.svg" alt="" fill />
        </div>
      </div> */}
      <div className="absolute py-[50px] px-[20px]">
        <p className="text-center font-libre-baskerville text-2xl font-semibold uppercase sm:text-3xl">
          {t("title")}
        </p>
        <div
          className="
            my-[50px]
            grid h-[1140px] max-w-[330px]
            grid-cols-1 grid-rows-3 place-content-center
            place-items-stretch gap-[30px] md:hidden
          "
        >
          <div className="row-span-1">
            {first && (
              <NewsCardLong news={first} direction="vertical" wideImage />
            )}
          </div>
          <div className="row-span-1">
            {second && (
              <NewsCardLong news={second} direction="vertical" wideImage />
            )}
          </div>
          <div className="row-span-1">
            {third && (
              <NewsCardLong news={third} direction="vertical" wideImage />
            )}
          </div>
        </div>
        <div
          className="
          my-[50px]
          grid
          h-[780px] w-[660px] grid-cols-2
          grid-rows-3 place-content-center place-items-stretch
          gap-[30px] max-md:hidden min-[1131px]:hidden"
        >
          <div className="col-span-2">
            {first && <NewsCardLong news={first} direction="image-left" />}
          </div>
          <div className="col-span-2">
            {second && <NewsCardLong news={second} direction="image-right" />}
          </div>
          <div className="col-span-2">
            {third && <NewsCardLong news={third} direction="image-left" />}
          </div>
        </div>
        <div
          className="
          my-[50px]
          grid h-[530px] w-[1020px]
          grid-cols-3 grid-rows-2 place-content-center
          place-items-stretch gap-[30px] max-[1130px]:hidden"
        >
          <div className="col-span-1 row-span-2">
            {first && <NewsCardLong news={first} direction="vertical" />}
          </div>
          <div className="col-span-2 flex flex-row">
            {second && <NewsCardLong news={second} direction="image-right" />}
          </div>
          <div className="col-span-2 flex flex-row">
            {third && <NewsCardLong news={third} direction="image-left" />}
          </div>
        </div>
        <Link href="/news">
          <button className="bg-clear text-md mx-auto block border border-black py-2 px-6 uppercase text-black">
            {t("buttonText")}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default LatestUpdatesSection;
